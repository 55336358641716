import HeaderContainer_IronScreenComponent from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/skinComps/IronScreen/IronScreen.skin';
import HeaderContainer_IronScreenController from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/HeaderContainer.controller';


const HeaderContainer_IronScreen = {
  component: HeaderContainer_IronScreenComponent,
  controller: HeaderContainer_IronScreenController
};


export const components = {
  ['HeaderContainer_IronScreen']: HeaderContainer_IronScreen
};

